@font-face {
    font-family: 'Neue Einstellung';
    src: local('Neue Einstellung Bold'), local('NeueEinstellung-Bold'),
        url('NeueEinstellung-Bold.woff2') format('woff2'),
        url('NeueEinstellung-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Einstellung';
    src: local('NeueEinstellung-Medium'),
        url('NeueEinstellung-Medium.woff2') format('woff2'),
        url('NeueEinstellung-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Einstellung';
    src: local('NeueEinstellung-Normal'),
        url('NeueEinstellung-Normal.woff2') format('woff2'),
        url('NeueEinstellung-Normal.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Einstellung';
    src: local('NeueEinstellung-SemiBold'),
        url('NeueEinstellung-SemiBold.woff2') format('woff2'),
        url('NeueEinstellung-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

